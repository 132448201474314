var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "content__number" }, [
      _vm._m(0),
      _c("span", [_vm._v(_vm._s(_vm.timeslot.num_auto))]),
    ]),
    _vm.queuePosition
      ? _c("div", { staticClass: "content__place" }, [
          _c("span", { staticClass: "content__place-title" }, [
            _vm._v("Ваше место в очереди:"),
          ]),
          _c("span", [_vm._v(" " + _vm._s(_vm.queuePosition))]),
        ])
      : _vm._e(),
    _c("span", { class: ["content__title", { small: _vm.confirmed }] }, [
      _vm._v(" " + _vm._s(_vm.timeslot.unload_name) + " "),
    ]),
    _c("span", { staticClass: "content__subTitle" }, [
      _vm._v(_vm._s(_vm.timeslot.culture_name)),
    ]),
    _vm.confirmed
      ? _c("div", { class: ["content__confirmed", { big: _vm.confirmed }] }, [
          _c("div", { staticClass: "mod-accent" }, [
            _vm._v("Ваш приезд подвержден!"),
          ]),
          _c("div", { staticClass: "mod-usual" }, [
            _vm._v(
              " Проезжайте на парковку и ждите SMS приглашения на разгрузку на номер: "
            ),
            _c("span", { staticClass: "mod-accent" }, [
              _vm._v(_vm._s(_vm.formattedPhone)),
            ]),
          ]),
        ])
      : _c("div", { staticClass: "content__date" }, [
          _c("img", {
            attrs: { src: require("@/assets/icons/Dater.svg"), alt: "date" },
          }),
          _c("span", { staticClass: "content__date-day" }, [
            _vm._v(_vm._s(_vm.date)),
          ]),
          _c(
            "svg",
            {
              class: ["clocker", { highlight: _vm.highlight }],
              attrs: {
                width: "24",
                height: "24",
                viewBox: "0 0 24 24",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z",
                  "stroke-width": "2.5",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _c("path", {
                attrs: {
                  d: "M12 7V12.3333L15 15",
                  "stroke-width": "2.5",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
            ]
          ),
          _c(
            "span",
            { class: ["content__date-time", { highlight: _vm.highlight }] },
            [_vm._v(" " + _vm._s(_vm.time) + " ")]
          ),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content__number-icon" }, [
      _c("img", {
        attrs: { src: require("@/assets/icons/Truck.svg"), alt: "icon" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }