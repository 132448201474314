export default {
  computed: {
    formattedPhone() {
      if (this.timeslot.driver_phone && this.timeslot.driver_phone.length) {
        const phone = this.timeslot.driver_phone

        return `+${phone[0]} (${phone.slice(1,4)}) ${phone.slice(4,7)}-${phone.slice(7,9)}-${phone.slice(9)}`
      }

      return ''
    },
  },
}
