<template>
  <div class="content">
    <div class="content__number">
      <div class="content__number-icon">
        <img src="@/assets/icons/Truck.svg" alt="icon" />
      </div>
      <span>{{ timeslot.num_auto }}</span>
    </div>

    <div v-if="queuePosition" class="content__place">
      <span class="content__place-title">Ваше место в очереди:</span>
      <span>&nbsp;{{ queuePosition }}</span>
    </div>

    <span :class="['content__title', { small: confirmed }]">
      {{ timeslot.unload_name }}
    </span>

    <span class="content__subTitle">{{ timeslot.culture_name }}</span>

    <div v-if="confirmed" :class="['content__confirmed', { big: confirmed }]">
      <div class="mod-accent">Ваш приезд подвержден!</div>
      <div class="mod-usual">
        Проезжайте на парковку и ждите SMS приглашения на разгрузку на номер:
        <span class="mod-accent">{{ formattedPhone }}</span>
      </div>
    </div>
    <div v-else class="content__date">
      <img src="@/assets/icons/Dater.svg" alt="date" />
      <span class="content__date-day">{{ date }}</span>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :class="['clocker', { highlight: highlight }]"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 7V12.3333L15 15"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span :class="['content__date-time', { highlight: highlight }]">
        {{ time }}
      </span>
    </div>
  </div>
</template>

<script>
import { GET_TIME } from '@/constants/date'
import { getFormattedTzDate } from '@/core'
import formattedPhoneMixin from '@/views/terminalUI/mixins/formattedPhoneMixin'
export default {
  name: 'TimeslotCard',
  mixins: [formattedPhoneMixin],
  props: {
    timeslot: {
      type: Object,
      default: () => ({}),
    },
    // подсветка даты (для приехавших заранее)
    highlight: {
      type: Boolean,
      default: false,
    },
    // для вывода инфы в нижней части карты
    confirmed: {
      type: Boolean,
      default: false,
    },
    // для вывода позиции в очереди
    queuePosition: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    date() {
      return getFormattedTzDate(this.timeslot.window_from, 'DD.MM.YY')
    },
    time() {
      return `${getFormattedTzDate(
        this.timeslot.window_from,
        GET_TIME,
      )}-${getFormattedTzDate(this.timeslot.window_to, GET_TIME)}`
    },
  },
}
</script>

<style lang="sass" scoped>
.content
  background-color: $color-white
  border-radius: 40px
  padding: 40px
  margin-bottom: 40px
  &__number
    display: flex
    align-items: center
    font-size: 20px
    margin-bottom: 20px
    &-icon
      display: flex
      justify-content: center
      align-items: center
      width: 36px
      height: 36px
      margin-right: 14px
      background: $backgroundGray
      border-radius: 8px
    span
      font-family: $fontSecondary

  &__place
    margin-bottom: 20px
    font-family: $fontPrimary
    font-size: 24px
    font-weight: 500
    line-height: 30px
    &-title
      color: $greyText

  &__title
    display: block
    font-weight: 500
    font-size: 32px
    font-family: $fontPrimary
    margin-bottom: 8px
    &.small
      font-size: 20px
  &__subTitle
    display: block
    color: $greyText
    font-family: $fontPrimary
    font-weight: 500
    font-size: 20px
    margin-bottom: 20px
  &__date
    display: flex
    align-items: center
    border-top: 1px solid $inputs-border-color
    padding-top: 20px
    font-size: 24px
    font-weight: 700
    img
      margin-right: 5px
    &-day
      font-family: $fontSecondary
      color: $greyText
      margin-right: 23px
    &-time
      font-family: $fontSecondary
      color: $greyText
      &.highlight
        color: $special
    .clocker
      stroke: $greyText
      &.highlight
        stroke: $special
  &__confirmed
    border-top: 1px solid $inputs-border-color
    padding-top: 20px
    font-size: 20px
    font-weight: 500
    line-height: 28px
    &.big
      font-size: 28px
      line-height: 36px
    .mod-accent
      font-family: $fontPrimary
      color: $special
    .mod-usual
      font-family: $fontPrimary
      color: $secondaryBlack
</style>
